import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "@emotion/styled"

const ContactContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: wrap;
      flex-flow: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
  margin-top: 10rem;
`

/* Header */
const ContactHeaderContainer = styled.div`
  margin-bottom: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  width: 100%;
`

const ContactTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
`

const ContactLine = styled.div`
  background-color: #7798ab;
  width: 1rem;
  margin: 0 auto;
  height: 0.1rem;
  margin-top: 0.5rem;
`

const ContactPage = () => (
    <Layout>
    <SEO title="Contact" />
        <ContactContainer>
            <ContactHeaderContainer>
                <ContactTitle>Contact</ContactTitle>
                <ContactLine>&nbsp;</ContactLine>
            </ContactHeaderContainer>
            <form name="contact" method="POST" netlify-honeypot="bot-field" data-netlify="true">
                <input type="hidden" name="form-name" value="contact" />
                <input type="text" name="name" placeholder="Name" />
                <input type="email" name="email" placeholder="Email" />
                <textarea name="message" placeholder="Message"></textarea>
                <input type="submit" value="Submit"></input>
            </form>
        </ContactContainer>
    </Layout>
)

export default ContactPage